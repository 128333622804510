<template>
  <div>
    <v-container
      v-if="form !== null"
      style="padding: 0 0 20px 0; margin: auto; border-radius: 5px; background:white;"
    >
      <v-form ref="entryForm" lazy-validation :disabled="loading">
        <v-card
          tile
          flat
          style="
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          position:sticky;
          top:60px;
          background:white;
          z-index:5;
        "
        >
          <div
            style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <p style="margin:auto 0;text-align:left; font-weight:bold;">
              DETAIL AKTIVITAS
              <v-tooltip bottom color="grey">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    text
                    icon
                    color="grey darken-2"
                    @click="detail"
                    style="position:relative; top:-3px;"
                  >
                    <v-icon small>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>refresh page</span>
              </v-tooltip>
            </p>
          </div>

          <div class="actionButton">
            <v-btn
              type="button"
              rounded
              elevation="0"
              color="red"
              class="white--text font-weight-bold ma-2"
              style="font-size:12px;"
              @click.prevent="close"
              :loading="loading"
              outlined
            >
              <v-icon small>mdi-chevron-left</v-icon>
              Kembali
            </v-btn>
          </div>
        </v-card>

        <v-card elevation="0" style="padding:0 15px; margin-bottom:20px;">
          <v-toolbar-title
            class="overline d-flex justify-content-between align-center"
            style="padding:0; text-align:left; height:30px;"
          >
            <p
              style="font-size: 20px; margin: 0; font-weight: bold; position:relative;"
            >
              {{ form.odoo_customer_name }}
            </p>
            <!-- <div style=" position:absolute; right:0; top:-20px;"> -->
            <p style="font-size:10px; margin: 0;">
              dibuat pada :
              {{ form.created_at }}
            </p>
            <!-- </div> -->
          </v-toolbar-title>
        </v-card>
        <v-divider></v-divider>
        <div
          :class="
            `d-flex ${
              wWidth > 780 ? 'flex-row' : 'flex-column'
            } justify-content-between align-center`
          "
        >
          <v-card elevation="0" style="padding:0 15px; width:100%;">
            <v-toolbar-title class="overline" style="padding-left:10px;">
              <p
                style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
              >
                Check In
              </p>
            </v-toolbar-title>

            <v-row no-gutters>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tanggal & Jam
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;margin-top:6px;">
                    <v-text-field
                      dense
                      readonly
                      v-model="form.check_in_info.check_in_at"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0;margin-top:6px; cursor: pointer;"
                    @click="
                      openMap(
                        form.check_in_info.lt_loc,
                        form.check_in_info.lg_loc
                      )
                    "
                  >
                    <v-text-field
                      dense
                      disabled
                      :value="
                        `${form.check_in_info.lt_loc}, ${form.check_in_info.lg_loc}`
                      "
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Catatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-textarea
                      dense
                      rows="1"
                      auto-grow
                      readonly
                      v-model="form.check_in_info.description"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-card elevation="0" style="padding:0 15px; width:100%;">
            <v-toolbar-title class="overline" style="padding-left:10px;">
              <p
                style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
              >
                Check Out
              </p>
            </v-toolbar-title>
            <v-row no-gutters v-if="form.check_out_info.check_out_at !== null">
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tanggal & Jam
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;margin-top:6px;">
                    <v-text-field
                      dense
                      readonly
                      v-model="form.check_out_info.check_out_at"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0;margin-top:6px; cursor: pointer;"
                    @click="
                      openMap(
                        form.check_out_info.lt_loc,
                        form.check_out_info.lg_loc
                      )
                    "
                  >
                    <v-text-field
                      dense
                      disabled
                      :value="
                        `${form.check_out_info.lt_loc}, ${form.check_out_info.lg_loc}`
                      "
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Catatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-textarea
                      dense
                      rows="1"
                      auto-grow
                      readonly
                      v-model="form.check_out_info.description"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters v-else style="height:200px">
              <v-col
                cols="12"
                style="padding: 0;"
                class="d-flex flex-column justify-center align-center"
              >
                <div style="padding: 0 10px">
                  <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color:red;
                      "
                  >
                    DATA BELUM TEREKAM
                  </p>
                </div>
                <div v-if="getUserProfile.employee.nik === form.nik">
                  <v-dialog v-model="checkOutDialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        type="button"
                        rounded
                        small
                        elevation="0"
                        color="primary"
                        class="white--text font-weight-bold ma-2"
                        style="font-size:12px;"
                        :loading="loading"
                      >
                        Check Out
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Form Check Out
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-col
                          md="12"
                          style="padding: 0; position:relative; top:20px;"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Catatan
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-textarea
                                outlined
                                placeholder="Berikan Catatan disini..."
                                v-model="form.check_out_info.description"
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="checkOutDialog = false"
                          :loading="loading"
                        >
                          Batal
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="checkOutNow"
                          color="primary"
                          type="button"
                          text
                          outlined
                          :loading="loading"
                        >
                          Check Out Now
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <v-divider></v-divider>
        <v-card elevation="0" class=" mx-auto" style="padding:10px 15px;">
          <v-toolbar-title class="overline" style="padding-left:10px;">
            <p
              style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
            >
              Detail Kunjungan
            </p>
          </v-toolbar-title>
          <v-row no-gutters>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Customer
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    dense
                    readonly
                    v-model="form.odoo_customer_name"
                    style="font-weight:bold;"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Code
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    dense
                    readonly
                    v-model="form.odoo_customer_code"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Alamat
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    readonly
                    v-model="form.odoo_customer_address"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px;">
                <v-col cols="12" style="padding: 0;">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Wilayah
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; display:flex; justify-content:center; align-items:center; margin-top:6.5px; margin-bottom:0;"
                >
                  <v-text-field
                    dense
                    readonly
                    v-model="form.odoo_region_group_name"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0;">
              <div style="padding: 0 10px;">
                <v-col cols="12" style="padding: 0;">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Area
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; display:flex; justify-content:center; align-items:center; margin-top:6.5px; margin-bottom:0;"
                >
                  <v-text-field dense readonly v-model="form.odoo_area_name" />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Bertemu dengan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-textarea
                    dense
                    rows="1"
                    auto-grow
                    readonly
                    v-model="form.meet_with"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-card elevation="0" style="padding:0 15px;">
          <v-toolbar-title class="overline" style="padding-left:10px;">
            <p
              style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
            >
              Photo
            </p>
          </v-toolbar-title>
          <v-row no-gutters>
            <v-col cols="12" style="padding: 0;" class="d-flex flex-wrap">
              <div
                v-for="(item, index) in form.photo"
                :key="index"
                style="padding:0; height:100px; width:100px; background-color:black; margin:5px; position:relative; border:2px solid black;border-radius:5px;"
              >
                <v-img
                  :src="item.photo"
                  :lazy-src="
                    `https://picsum.photos/10/6?image=${index * 5 + 10}`
                  "
                  style="width:100%; height:100%; border-radius:5px; cursor:pointer;"
                  class="grey lighten-2"
                  @click="openImage(item)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="d-flex justify-center align-center"
                  style="position:absolute; bottom:0; right:0; height:30px; width:30px; background-color:red; border:1px solid black; border-radius:15px 0 0 0;"
                >
                  <v-btn
                    @click="
                      deletePhoto(
                        item.photo.slice(item.photo.lastIndexOf('/') + 1)
                      )
                    "
                    rounded
                    small
                    elevation="0"
                    icon
                  >
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              v-if="form.photo === null || form.photo.length === 0"
              cols="12"
              class="d-flex flex-column justify-center align-center"
              style="padding: 0;"
            >
              <div
                style="padding: 0 10px; color:red; font-weight:bold; font-size:14px;"
              >
                BELUM ADA PHOTO
              </div>
            </v-col>
            <v-col
              v-if="getUserProfile.employee.nik === form.nik"
              cols="12"
              class="d-flex flex-column justify-center align-center"
              style="padding: 0;margin-top:10px;"
            >
              <div style="padding: 0 10px">
                <v-dialog v-model="addPhotoDialog" width="500" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      type="button"
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="white--text font-weight-bold ma-2"
                      style="font-size:12px;"
                      :loading="loading"
                    >
                      Tambah Photo
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Tambah Foto
                    </v-card-title>

                    <v-card-text class="d-flex justify-center align-center">
                      <v-col
                        cols="12"
                        style="padding: 0; position:relative; top:20px;"
                      >
                        <div
                          style="padding: 10px;border:2px dashed grey; height:70px;"
                        >
                          <v-file-input
                            ref="fileInput"
                            placeholder="Upload Photo"
                            dense
                            append-icon="mdi-paperclip"
                            prepend-icon=""
                            @change="uploadPhoto"
                            :rules="rules.uploadPhotoRules"
                          ></v-file-input>
                        </div>
                      </v-col>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="error"
                        text
                        outlined
                        :loading="loading"
                        @click="closeAddPhotoDialog"
                      >
                        Batal
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        type="button"
                        text
                        outlined
                        :loading="loading"
                        :disabled="photoName === null"
                        @click="submitPhoto"
                      >
                        Simpan Foto
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-divider></v-divider>
        <v-card elevation="0" style="padding:0 15px;">
          <v-toolbar-title class="overline" style="padding-left:10px;">
            <p
              style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;position:relative; margin-bottom:10px;"
            >
              Info User
            </p>
          </v-toolbar-title>
          <v-row no-gutters>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    NIK
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-text-field dense readonly v-model="form.nik" />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0;">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Nama
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;margin-top:6px;">
                  <v-text-field dense readonly v-model="form.employee_name" />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tgl. Update
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field dense readonly v-model="form.updated_at" />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>

      <v-dialog v-model="imageDialog" persistent>
        <div style="position:relative;">
          <v-img
            :src="
              imageLink !== null
                ? imageLink
                : 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
            "
            @error="imgError"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-btn
            style="position:absolute; z-index:1; top:10px; right:10px; font-weight:bolder;"
            fab
            x-small
            color="black"
            @click="imageDialog = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-dialog>
    </v-container>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width:100%; height:80vh;position:relative;"
    >
      <v-btn
        type="button"
        rounded
        elevation="0"
        color="red"
        class="white--text font-weight-bold ma-2"
        style="font-size:12px; position:absolute; top: 10px; left:10px;"
        @click.prevent="close"
        :loading="loading"
        outlined
      >
        <v-icon small>mdi-chevron-left</v-icon>
        Kembali
      </v-btn>
      <p style="color: RGBA(0,0,0,0.5); font-size:24px; font-weight:bold;">
        DATA NOT FOUND
      </p>
    </div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="height:100%; position:fixed; top:0; left:0; right:0; bottom:0; width:100%; z-index:6; background-color:rgba(0,0,0,0.1);"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'activitydetail',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      oAuthApi: buildType.apiURL('oauth'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      checkOutDialog: false,
      loading: false,
      isEdit: false,
      form: null,
      wWidth: window.innerWidth,
      rules: {
        uploadPhotoRules: []
      },
      imageLink: null,
      photoName: null,
      addPhotoDialog: false,
      imageDialog: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.loading = true
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.detail()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async detail() {
      this.loading = true
      let url = ''
      if (this.$route.query.redirect_from !== undefined) {
        url = `${this.hrsApi}salesman_activity/detail/${this.$route.params.id}?redirect_from=inappnotification`
      } else {
        url = `${this.hrsApi}salesman_activity/detail/${this.$route.params.id}`
      }
      await axios
        .get(url)
        .then(res => {
          console.log(res.data.data)
          if (res.data.status_code !== '-99') {
            this.form = res.data.data
            if (res.data.data.permission_type === null) {
              this.form.permission_type = '-'
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    close() {
      if (this.form !== null) {
        this.$refs.entryForm.reset()
      }
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/hr/activity/submission') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/hr/activity')
        }
      } else {
        this.$router.push('/hr/activity')
      }
    },
    async checkOutNow() {
      this.loading = true
      const d = new Date()
      const date =
        d.getDate().toString().length === 1 ? `0${d.getDate()}` : d.getDate()
      let month = d.getMonth() + 1
      if (month.toString().length === 1) {
        month = `0${month}`
      }
      const year = d.getFullYear()
      const hours = d.getHours()
      const minutes = d.getMinutes()
      const seconds = d.getSeconds()
      this.form.check_out_info.check_out_at = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

      this.getLocation()
    },
    getLocation() {
      if (navigator.geolocation) {
        this.isLocationSupported = true
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        this.isLocationSupported = false
        this.checkOutDialog = false
        this.detail()
        this.loading = false
        this.showMsgDialog(
          'warning',
          'Aktifkan dahulu akses lokasi anda !',
          false
        )
      }
      // navigator.permissions.query({ name: 'geolocation' }).then(res => {
      //   console.log(res.state)
      //   if (res.state === 'granted') {
      //     this.isLocationSupported = true
      //     navigator.geolocation.getCurrentPosition(this.showPosition)
      //   } else {
      //     this.isLocationSupported = false
      //     this.checkOutDialog = false
      //     this.detail()
      //     this.loading = false
      //     this.showMsgDialog(
      //       'warning',
      //       'Aktifkan dahulu akses lokasi anda !',
      //       false
      //     )
      //   }
      // })
    },
    showPosition(position) {
      const form = {
        activity_id: this.$route.params.id,
        lt_loc: position.coords.latitude,
        lg_loc: position.coords.longitude,
        check_out_at: this.form.check_out_info.check_out_at,
        description: this.form.check_out_info.description
      }
      console.log(form)
      this.submitCheckOut(form)
    },
    async submitCheckOut(form) {
      await axios
        .post(`${this.hrsApi}salesman_activity/check_out`, form)
        .then(res => {
          console.log(res)
          this.detail()
          this.loading = false
          this.checkOutDialog = false
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          return null
        })
        .catch(err => {
          this.showMsgDialog('error', err.message, false)
          this.loading = false
          this.checkOutDialog = false
          return null
        })
    },
    openMap(lat, lng) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      )
    },
    deletePhoto(photoName) {
      this.showMsgDialog('question', 'Hapus photo, Anda yakin ?', true).then(
        async res => {
          if (res.isConfirmed) {
            this.unlinkPhoto(photoName)
          }
        }
      )
    },
    async unlinkPhoto(photoName, pValue) {
      this.loading = true
      const form = {
        file_name: photoName,
        module: 'sales_activities'
      }
      await axios
        .post(`${this.upload}esanqua/hris/unlink`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            if (pValue === undefined) {
              this.removeFileFromDetail(photoName)
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
            if (res.data.status_msg === 'File not found') {
              this.removeFileFromDetail(photoName)
            }
          }
          this.loading = false
          if (pValue) {
            setTimeout(() => {
              this.photoName = null
              this.$refs.fileInput.reset()
              this.addPhotoDialog = false
            }, 300)
          }
          // this.detail()
          return null
        })
        .catch(err => {
          this.showMsgDialog('error', err.message, false)
          this.loading = false
          this.detail()
          return null
        })
    },

    removeFileFromDetail(fName) {
      for (let i = 0; i < this.form.photo.length; i++) {
        if (this.form.photo[i].photo.includes(fName)) {
          this.form.photo.splice(i, 1)
        }
      }
      this.updatePhoto(false)
    },
    async updatePhoto(isAddFile) {
      this.loading = true
      const photo = []
      if (this.form.photo != null) {
        for (let i = 0; i < this.form.photo.length; i++) {
          const fName = this.form.photo[i].photo.slice(
            this.form.photo[i].photo.lastIndexOf('/') + 1
          )
          photo.push({
            photo: fName
          })
        }
      }
      if (isAddFile) {
        photo.push({
          photo: this.photoName
        })
      }
      const form = {
        activity_id: this.$route.params.id,
        photo: photo
      }
      await axios
        .post(`${this.hrsApi}salesman_activity/update_photo`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)

            this.unlinkDokumen(this.photoName)
          }
          if (isAddFile) {
            this.photoName = null
            this.$refs.fileInput.reset()
          }
          this.loading = false
          this.detail()
          return null
        })
        .catch(err => {
          if (isAddFile) {
            this.photoName = null
            this.$refs.fileInput.reset()
          }
          this.showMsgDialog('error', err.message, false)
          this.loading = false
          this.detail()
          return null
        })
    },
    uploadPhoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'sales_activities')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then(res => {
            if (res.data.status === true) {
              this.showMsgDialog(
                'success',
                'Photo siap disimpan, Silahkan lanjutkan dengan klik tombol "simpan photo"',
                false
              )
              this.photoName = res.data.data.name
            } else {
              this.photoName = null
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.photoName = null
            this.showMsgDialog('error', err.message, false)
          })
      } else {
        this.photoName = null
      }
    },
    submitPhoto() {
      // this.rules.uploadPhotoRules = [v => !!v || 'No file uploaded']
      // const self = this
      // setTimeout(function() {
      //   if (self.$refs.fileInput.validate()) {
      this.updatePhoto(true)
      //   }
      // })
    },
    closeAddPhotoDialog() {
      if (this.photoName !== null) {
        this.unlinkPhoto(this.photoName, true)
      } else {
        setTimeout(() => {
          this.addPhotoDialog = false
          this.$refs.fileInput.reset()
        }, 300)
      }
    },
    openImage(img) {
      this.imageLink = null

      this.imageLink = img.photo
      setTimeout(() => {
        this.imageDialog = true
      }, 300)
    },
    imgError() {
      console.log('error image')
      // this.isImgError = true
      this.imageLink =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }
}
</style>
